import { createContext, useContext } from 'react';

// To override the default context of Modal which is document.body by default,
// but we need to override it for some provider where our app is just a part of the page
const ModalGlobalContainerContext = createContext<HTMLElement | null>(null);

export const useModalGlobalContainerContext = () => {
  return useContext(ModalGlobalContainerContext);
};

export default ModalGlobalContainerContext;
