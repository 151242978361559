import { Box } from '@unifyapps/ui/components/Box';
import ReactTable from '@unifyapps/table/components/ReactTable';
import { useCallback, useMemo } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import { useTranslation } from '@unifyapps/i18n/client';
import SvgAlertTriangle from '@unifyapps/icons/outline/AlertTriangle';
import { Button } from '@unifyapps/ui/components/Button';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import AsyncView from '../../../AsyncView';
import TextCell from '../../../Table/cells/old/TextCell';
import type { MediaTypeViewerProps } from '../../types';
import MediaViewerEmptyState from '../../components/EmptyState';
import MediaViewerLoadingState from '../../components/LoadingState';
import { useCsvContentFromUrl } from './hooks/useCsvContent';

function CsvRendererTable(props: { rows: string[][] | null }) {
  // treating the first row as table headers
  const columns = props.rows?.[0];

  const tableColumns = useMemo(
    () =>
      columns?.map((col, index) => ({
        header: col,
        // camelCase + index to generate keys, which are used while filling
        // data into the table (internal handling, not exposed)
        accessorKey: `${col}_${index}`,
        muiTableBodyCellProps: {
          className: 'text-primary font-medium',
        },
        Cell: TextCell,
      })),
    [columns],
  );

  const tableData = useMemo(
    () =>
      props.rows?.slice(1).map((row) => {
        const obj: Record<string, string> = {};
        columns?.forEach((col, i) => {
          const colKey = `${col}_${i}`;
          obj[colKey] = row[i];
        });
        return obj;
      }),
    [columns, props.rows],
  );

  if (!tableColumns || !tableData) {
    return null;
  }

  return (
    <Stack className="h-full w-full">
      <Box className="p-sm bg-brand-secondary rounded-4xl h-fit max-h-full w-full">
        <ReactTable
          columns={tableColumns}
          data={tableData}
          enableStickyHeader
          layoutMode="grid-no-grow"
          // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop -- is okay
          muiTableContainerProps={{
            className: 'rounded-lg overflow-y-auto',
          }}
        />
      </Box>
    </Stack>
  );
}

const ICON_COMPONENT = <FeaturedIcon Icon={SvgAlertTriangle} color="error" size="lg" />;
export default function CsvViewerWrapper(props: MediaTypeViewerProps) {
  const { content, isLoading, error } = useCsvContentFromUrl(props.media.url);
  const { t } = useTranslation(['common', 'workflows']);

  const closeButton = useMemo(
    () => (
      <Button
        className="!rounded-4xl"
        color="neutral"
        onClick={props.onClose}
        size="md"
        variant="outline"
      >
        {t('common:Actions.Close')}
      </Button>
    ),
    [t, props.onClose],
  );

  const renderEmptyState = useCallback(
    () => (
      <MediaViewerEmptyState
        IconNode={ICON_COMPONENT}
        description={t('workflows:PreviewEmptyStateDescription')}
        primaryAction={closeButton}
        title={t('common:EmptyState.NoDataFound')}
      />
    ),
    [t, closeButton],
  );

  const renderLoadingState = useCallback(
    () => <MediaViewerLoadingState title={`${t('common:Loading')}...`} />,
    [t],
  );

  return (
    <AsyncView
      data={content}
      error={error}
      isLoading={isLoading}
      renderEmptyState={renderEmptyState}
      renderLoader={renderLoadingState}
    >
      <CsvRendererTable rows={content} />
    </AsyncView>
  );
}
