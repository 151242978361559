import type { useKeenSlider } from 'keen-slider/react';
import { useCallback, useEffect, useRef } from 'react';

export const useCarouselAutoplay = ({
  autoPlay,
  instanceRef,
}: {
  autoPlay?: {
    enabled: boolean;
    duration?: number;
  };
  instanceRef: ReturnType<typeof useKeenSlider>[1];
}) => {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const currentDuration = useRef<number>(autoPlay?.duration || 3000);

  const autoPlayCarousel = useCallback(() => {
    instanceRef.current?.next();
    timer.current = setTimeout(autoPlayCarousel, currentDuration.current);
  }, [instanceRef]);

  const clearAndResetTimer = useCallback(() => {
    clearTimeout(timer.current);
    if (autoPlay?.enabled) {
      timer.current = setTimeout(autoPlayCarousel, currentDuration.current);
    }
  }, [autoPlay?.enabled, autoPlayCarousel]);

  useEffect(() => {
    currentDuration.current = autoPlay?.duration || 3000;
    clearAndResetTimer();
  }, [autoPlay?.duration, clearAndResetTimer]);

  useEffect(() => {
    if (autoPlay?.enabled) {
      clearAndResetTimer();
    } else {
      clearTimeout(timer.current);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [autoPlay?.enabled, clearAndResetTimer]);

  return { clearAndResetTimer };
};
