'use client';
import type { SheetProps } from '@unifyapps/ui/_components/Sheet';
import { useTable } from '../hooks/useTable';
import type { CustomColumnDef, MRT_RowData, MRT_ColumnDef, MRT_TableOptions } from '../types';
import { TableSheet } from './table/TableSheet';

type ReactTableProps<TData extends MRT_RowData> = MRT_TableOptions<TData> & {
  sx?: SheetProps['sx'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- MRT uses any too. see MRT_TableOptions
  columns: (MRT_ColumnDef<TData, any> & CustomColumnDef<TData>)[];
};

function ReactTable<TData extends MRT_RowData>(props: ReactTableProps<TData>) {
  // using search adds globalFilter to the table which inherently filters values
  const table = useTable({
    manualFiltering: true,
    enableSorting: false,
    enablePagination: false,
    ...props,
  });

  return <TableSheet table={table} />;
}

export default ReactTable;
