import type { DetailedHTMLProps, ImgHTMLAttributes, ReactEventHandler } from 'react';
import { useState } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import useToggle from '@unifyapps/hooks/useToggle';

export function ImageWithFallback({
  src,
  alt,
  fallbackSrc,
  className,
  fallbackImgClassname,
  onError,
  ...rest
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  fallbackSrc: string;
  fallbackImgClassname?: string;
}) {
  const [imgSrc, setImgSrc] = useState(src);
  const [errorOccurred, { on }] = useToggle(false);

  const handleError: ReactEventHandler<HTMLImageElement> = useEventCallback((error) => {
    setImgSrc(fallbackSrc);
    onError?.(error);
    on();
  });

  return (
    <img
      alt={alt}
      className={errorOccurred ? (fallbackImgClassname ?? className) : className}
      onError={handleError}
      src={imgSrc}
      {...rest}
    />
  );
}
