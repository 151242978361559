import type { IndexedObject, UppyFile } from '@uppy/core';
import { Uppy } from '@uppy/core';

export type UppyFileMeta = {
  name?: string;
  type?: string;
  relativePath?: string;
  isImporting?: boolean;
  referenceId?: string;
  previewUrl?: string;
  entityId?: string;
};

export enum UploadError {
  UnSupportedType = 'UnSupportedType',
  DuplicateFile = 'DuplicateFile',
  FileSize = 'FileSize',
  SaveError = 'SaveError',
}

export type UploadedFile<TMeta extends IndexedObject<unknown> = UppyFileMeta> = UppyFile<TMeta> & {
  url?: string;
  fileProgress?: number;
  relativePath?: string;
  error?: UploadError;
  retryAble?: boolean;
  /**
   * @deprecated deprecated, avoid usage, will be removed soon
   */
  s3Multipart?: {
    key: string;
  };
  uploadResponse?: Record<string, string | undefined>;
};

export type UppyManager<File> = {
  adaptUploadFile?: (file: File) => File;
  onFileAdded?: (file: File) => File;
  onProgress?: (file: File) => void;
  onRestrictedFile?: (file: File, error: Error) => void;
  onUploadSuccess?: (
    file: File,
    options: {
      removeFileById: (fileId: string) => void;
    },
  ) => Promise<File>;
  sourceId?: string;
  referenceId: string;
  shouldHandleFile?: (file: UppyFile) => boolean;
};

export type FilesState<T> = Record<string, T>;

export enum FilesActionType {
  RemoveFile = 'REMOVE_FILE',
  RenameFile = 'RENAME_FILE',
  ClearFiles = 'CLEAR_FILES',
  AddOrUpdateFile = 'ADD_OR_UPDATE_FILE',
  UpdateFile = 'UPDATE_FILE',
  UpdateFiles = 'UPDATE_FILES',
}

export interface RemoveFileAction {
  type: FilesActionType.RemoveFile;
  payload: {
    value: string;
  };
}

export interface RenameFileAction {
  type: FilesActionType.RenameFile;
  payload: {
    uppy: Uppy | null;
    fileId: string;
    name: string;
  };
}

export interface ClearFilesAction {
  type: FilesActionType.ClearFiles;
  payload: {
    referenceId: string;
  };
}

export interface AddOrUpdateFileAction<T> {
  type: FilesActionType.AddOrUpdateFile;
  payload: {
    fileId: string;
    value: T;
  };
}

export interface UpdateFileAction {
  type: FilesActionType.UpdateFile;
  payload: {
    fileId: string;
    value: { error: string };
  };
}

export interface UpdateFilesAction<T> {
  type: FilesActionType.UpdateFiles;
  payload: {
    files: Record<string, T>;
  };
}

export type FilesAction<T> =
  | RemoveFileAction
  | RenameFileAction
  | ClearFilesAction
  | AddOrUpdateFileAction<T>
  | UpdateFileAction
  | UpdateFilesAction<T>;

export { Uppy };
export type { UppyFile };
