import { useEffect, useState } from 'react';
import { getPreviewUrl, adaptPrivateUrl } from '../../../helpers/getPreviewUrl';

async function parseStringToCsvJSON(content: string) {
  /**
   * lazy importing papaparse
   */
  const parser = await import('papaparse');

  const parsedJson = parser.parse(content, {
    delimiter: ',',
    preview: 100,
  });

  return parsedJson;
}

export function useCsvContentFromUrl(url: string | undefined) {
  const [content, setContent] = useState<string[][] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!url) {
          setIsLoading(false);
          return;
        }

        // we need to adapt url because uploaded files have domain as qa.unifyapps.com
        // which breaks CSP in localhost:3000
        const adaptedUrl = adaptPrivateUrl(getPreviewUrl(url));

        const response = await fetch(adaptedUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const text = await response.text();
        const parsedJson = await parseStringToCsvJSON(text);

        setContent(parsedJson.data as string[][]);
      } catch (e) {
        setError(e as Error);
      }
      setIsLoading(false);
    };

    void fetchData();
  }, [url]);

  return {
    content,
    error,
    isLoading,
  };
}
