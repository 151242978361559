import type { MediaType } from '../MediaViewer/types';

type MediaItemType = Pick<MediaType, 'mimeType' | 'url' | 'fileType'>;

const IMAGE_REGEX = /\.(?:jpe?g|png|gif|bmp|webp|svg|avif)(?:.*)?$/i;
const VIDEO_REGEX = /\.(?:mp4|webm|ogg)(?:.*)?$/i;
const PDF_REGEX = /\.pdf(?:.*)?$/i;
const CSV_REGEX = /\.csv(?:.*)?$/i;

export const hasImageExtension = (url: string) => IMAGE_REGEX.test(url);

export const hasVideoExtension = (url: string) => VIDEO_REGEX.test(url);

export const hasPdfExtension = (url: string) => PDF_REGEX.test(url);

export const hasCsvExtension = (url: string) => CSV_REGEX.test(url);

export const validateAndGetPreviewUrl = (url: string) => (hasImageExtension(url) ? url : undefined);

export const isImage = ({ mimeType, url }: MediaItemType) => {
  return Boolean(mimeType?.startsWith('image') || hasImageExtension(url));
};

export const isVideo = ({ mimeType, url }: MediaItemType) => {
  return Boolean(mimeType?.startsWith('video') || hasVideoExtension(url));
};

export const isPdf = ({ mimeType, url }: MediaItemType) => {
  return mimeType === 'application/pdf' || hasPdfExtension(url);
};

export const isCsv = ({ mimeType, url, fileType }: MediaItemType) => {
  return (
    mimeType === 'text/csv' ||
    ['csv', 'text/csv'].includes(fileType?.toLowerCase() ?? '') ||
    hasCsvExtension(url)
  );
};
