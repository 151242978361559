import type React from 'react';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useLatest } from 'react-use';
import type { FileProgress, SuccessResponse, Uppy, UppyFile } from '@uppy/core';
import find from 'lodash/find';
import identity from 'lodash/identity';
import omit from 'lodash/omit';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import { getUploadError } from './error';
import type { FilesAction, FilesState, UploadedFile, UppyManager } from './types';
import { FilesActionType, UploadError } from './types';
import { useUppyContext } from './UppyContext';

export const sanitizeFileUrl = (fileUrl?: string) => {
  if (!fileUrl) return '';

  const decodedFileUrl = decodeURIComponent(fileUrl);
  const removedDoubleSlashUrl = decodedFileUrl.replace(/(?<temp1>[^:]\/)\/+/g, '$1');
  return removedDoubleSlashUrl;
};

const INITIAL_STATE = {};

function reducer<T extends UploadedFile>(
  state: FilesState<T>,
  action: FilesAction<T>,
): FilesState<T> {
  switch (action.type) {
    case FilesActionType.RemoveFile: {
      return omit(state, action.payload.value);
    }
    case FilesActionType.RenameFile: {
      const { fileId, uppy, name } = action.payload;
      const currentFile = state[fileId];
      const meta = { ...currentFile.meta, name };
      uppy?.setFileMeta(fileId, meta);

      return { ...state, [fileId]: { ...currentFile, meta } };
    }

    case FilesActionType.ClearFiles: {
      const { referenceId } = action.payload;
      const otherFiles = Object.entries(state).reduce<Record<string, T>>((acc, [fileId, file]) => {
        if (file.meta.referenceId !== referenceId) {
          acc[fileId] = file;
        }

        return acc;
      }, {});

      return otherFiles;
    }
    case FilesActionType.AddOrUpdateFile: {
      const { fileId, value } = action.payload;

      return { ...state, [fileId]: value };
    }
    case FilesActionType.UpdateFile: {
      const { fileId, value } = action.payload;
      const currentFile = state[fileId];

      return { ...state, [fileId]: { ...currentFile, ...value } };
    }
    case FilesActionType.UpdateFiles: {
      return action.payload.files;
    }
    default:
      return state;
  }
}

function getPreviewBlobUrl(file?: UppyFile) {
  try {
    return file?.data ? URL.createObjectURL(file.data) : '';
  } catch (e) {
    return '';
  }
}

function useUppy<T extends UploadedFile>(
  {
    onFileAdded,
    onUploadSuccess,
    onProgress,
    onRestrictedFile,
    adaptUploadFile = identity,
    sourceId = '',
    referenceId,
    shouldHandleFile,
  }: UppyManager<T>,
  uppyInstance?: Uppy | null,
) {
  const [files, dispatch] = useReducer<React.Reducer<FilesState<T>, FilesAction<T>>>(
    reducer,
    INITIAL_STATE,
  );
  const { showSnackbar } = useSnackbar();
  const showErrorSnackbar = useCallback(
    (msg?: string) => {
      showSnackbar({
        color: 'error',
        title: msg ?? 'Something Went Wrong',
      });
    },
    [showSnackbar],
  );
  const contextUppy = useUppyContext();
  const uppy = uppyInstance ?? contextUppy;
  const latestOnUploadSuccess = useLatest(onUploadSuccess);
  const latestOnProgress = useLatest(onProgress);

  const isUploading = useMemo(
    () => Boolean(find(files, (file) => !file.url && !file.error)),
    [files],
  );

  const removeFile = useCallback(
    (file: T) => {
      uppy?.removeFile(file.id);
      dispatch({ type: FilesActionType.RemoveFile, payload: { value: file.id } });
    },
    [uppy],
  );

  const removeFileById = useCallback(
    (id: string) => {
      uppy?.removeFile(id);
      dispatch({ type: FilesActionType.RemoveFile, payload: { value: id } });
    },
    [uppy],
  );

  const renameFile = useCallback(
    (fileId: string, name: string) => {
      dispatch({ type: FilesActionType.RenameFile, payload: { uppy, fileId, name } });
    },
    [uppy],
  );

  const clearAll = useCallback(() => {
    dispatch({ type: FilesActionType.ClearFiles, payload: { referenceId } });

    const newFiles = Object.entries(files).reduce<Record<string, T>>((acc, [fileId, file]) => {
      if (file.meta.referenceId === referenceId) {
        uppy?.removeFile(fileId);
      } else {
        acc[fileId] = file;
      }

      return acc;
    }, {});

    if (Object.keys(newFiles).length === 0) {
      uppy?.cancelAll();
    }
  }, [files, referenceId, uppy]);

  useEffect(() => {
    const shouldProcessEvent = (file: UppyFile) =>
      (!sourceId || sourceId === file.source) && (!shouldHandleFile || shouldHandleFile(file));
    const _onFileAdded = (file: UppyFile) => {
      if (shouldProcessEvent(file)) {
        uppy?.setFileMeta(file.id, {
          ...file.meta,
          referenceId: file.meta.referenceId ?? referenceId,
          previewUrl: getPreviewBlobUrl(file),
        });
        if (referenceId && file.meta.referenceId !== referenceId) {
          return;
        }
        const addedFile = onFileAdded?.(file as unknown as T) || file;
        _onProgress(addedFile, file.progress);
      }
    };

    const _onProgress = (file?: UppyFile, progress?: FileProgress) => {
      if (!file) return;
      if (referenceId && file.meta.referenceId !== referenceId) {
        return;
      }
      if (shouldProcessEvent(file)) {
        const updatedFile = {
          ...file,
          referenceId,
          fileProgress: Math.round(
            ((progress?.bytesUploaded ?? 0) * 100) / (progress?.bytesTotal ?? 1),
          ),
        } as unknown as T;
        latestOnProgress.current?.(updatedFile);
        dispatch({
          type: FilesActionType.AddOrUpdateFile,
          payload: {
            fileId: file.id,
            value: adaptUploadFile({
              ...updatedFile,
            }),
          },
        });
      }
    };

    const _onRestrictedFile = (file?: UppyFile, error?: Error) => {
      onRestrictedFile?.(file as unknown as T, error ?? new Error('Restricted file error'));
      if (file && shouldProcessEvent(file) && !file.id) {
        file.id = `${Date.now()}`;
        file.meta = file.meta || {};
        file.meta.name = file.name;
        file.meta.type = file.type;

        error && showErrorSnackbar(getUploadError(error));
      }
    };

    const _uploadCompleted = (file?: UppyFile, body?: SuccessResponse) => {
      if (!file || !shouldProcessEvent(file)) return;
      if (referenceId && file.meta.referenceId !== referenceId) {
        return;
      }

      const updatedFile = {
        ...file,
        url: body?.uploadURL,
        uploadResponse: body?.body as never,
      } as unknown as T;
      if (latestOnUploadSuccess.current) {
        latestOnUploadSuccess
          .current(updatedFile, {
            removeFileById,
          })
          .then((uploadedFile) => {
            dispatch({
              type: FilesActionType.AddOrUpdateFile,
              payload: {
                fileId: file.id,
                value: uploadedFile,
              },
            });
          })
          .catch((err: Error) => {
            const error = err.message || UploadError.SaveError;
            dispatch({
              type: FilesActionType.UpdateFile,
              payload: { fileId: file.id, value: { error } },
            });
          });
      } else {
        dispatch({
          type: FilesActionType.AddOrUpdateFile,
          payload: {
            fileId: file.id,
            value: updatedFile,
          },
        });
      }
    };

    const onUploadError = (file: unknown, error: unknown, response: unknown) =>
      console.error('upload-error', { file, error, response });

    const onError = (error: unknown) => console.error('error', error);

    uppy?.on('upload-progress', _onProgress);
    uppy?.on('file-added', _onFileAdded);
    uppy?.on('restriction-failed', _onRestrictedFile);
    uppy?.on('upload-success', _uploadCompleted);
    uppy?.on('upload-error', onUploadError);
    uppy?.on('error', onError);

    return () => {
      uppy?.off('file-added', _onFileAdded);
      uppy?.off('upload-progress', _onProgress);
      uppy?.off('restriction-failed', _onRestrictedFile);
      uppy?.off('upload-success', _uploadCompleted);
      uppy?.off('upload-error', onUploadError);
      uppy?.off('error', onError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ask @Tapas
  }, [uppy]);

  const blobUpload = useCallback(
    ({ blob, name, type }: { blob: Blob; type: string; name: string }) => {
      uppy?.addFile({ name, type, data: blob });

      return new Promise<UploadedFile>((resolve, reject) => {
        uppy?.on('upload-success', (file, response) => {
          if (name === file?.name) {
            resolve({ ...file, url: response.uploadURL });
          }
          reject();
        });
      });
    },
    [uppy],
  );

  const filteredFiles = useMemo(
    () =>
      Object.entries(files).reduce<FilesState<T>>((acc, [key, file]) => {
        if (file.meta.referenceId === referenceId) {
          acc[key] = file;
        }

        return acc;
      }, {}),
    [files, referenceId],
  );

  const updateFiles = useCallback((updatedFiles: FilesState<T>) => {
    dispatch({ type: FilesActionType.UpdateFiles, payload: { files: updatedFiles } });
  }, []);

  return {
    uppy,
    files: filteredFiles,
    removeFile,
    removeFileById,
    clearAll,
    renameFile,
    isUploading,
    blobUpload,
    updateFiles,
  };
}

export default useUppy;
