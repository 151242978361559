import type { MediaTypeViewerProps } from '../types';
import { getPreviewUrl } from '../helpers/getPreviewUrl';

function PDFViewer(props: MediaTypeViewerProps) {
  const { media } = props;

  return (
    <embed
      className="size-full"
      src={getPreviewUrl(media.url)}
      title={media.caption || media.name}
      type="application/pdf"
    />
  );
}

export default PDFViewer;
