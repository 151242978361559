/* eslint-disable tsdoc/syntax -- not-needed*/
// ref: https://raw.githubusercontent.com/transloadit/uppy/ab88612dff3ce24b001acb3b626516f0e2f7fd0c/packages/%40uppy/drag-drop/src/index.js
import type { DragEventHandler } from 'react';
import React from 'react';
import isDragDropSupported from '@uppy/utils/lib/isDragDropSupported';
import toArray from '@uppy/utils/lib/toArray';
import _ from 'lodash';
import { Box } from '@unifyapps/ui/components/Box';
import FileInput, { addFiles, isFileTypeAllowed } from '../FileInput';
import type { Uppy, UppyFileMeta } from '../../hooks/useUppy';
import { stopEvent, stopEventPropagation } from '../../utils/event';

type Props = {
  uppy: Uppy | null;
  multiple?: boolean | null;
  disabled?: boolean;
  accept?: string;
  className?: string;
  fileMeta?: UppyFileMeta & Object;
  DragView: React.ReactElement;
  onError?: (error: boolean) => void;
  referenceId?: string;
  maxNumberOfFiles?: number;
  maxFileSizeInMB?: number;
};

class DragDrop extends React.Component<
  React.PropsWithChildren<Props>,
  { isDraggingOver: boolean }
> {
  isDragDropSupported = isDragDropSupported();
  removeDragOverClassTimeout: ReturnType<typeof setTimeout>;

  constructor(props: Props) {
    super(props);
    this.state = { isDraggingOver: false };
  }

  input = React.createRef<HTMLInputElement>();

  /**
   * Filter files that match the provided mime type group(all files accepted if none provided),
   * and trigger error if any file does not match provided type
   * @param data files dropped in the drag & drop area
   * @returns all filtered files if multiple allowed(default), else the 1st file in the
   * filtered array if none previously added else empty array(ignore dropped file)
   */
  adaptFiles = (data: any) => {
    const files = toArray(data);
    const adaptedFiles = this.props.accept
      ? files.filter((file) => isFileTypeAllowed(this.props.accept!, file))
      : files;
    const hasError = files.length !== adaptedFiles.length;
    this.props.onError && this.props.onError(hasError);

    return this.props.multiple === undefined || this.props.multiple
      ? adaptedFiles
      : this.props.uppy?.getFiles().length === 0
        ? adaptedFiles.slice(0, 1)
        : [];
  };

  handleDrop: DragEventHandler = (event) => {
    stopEvent(event);
    clearTimeout(this.removeDragOverClassTimeout);

    // 2. Remove dragover class
    this.setState({ isDraggingOver: false });

    // 3. Add all dropped files
    this.props.uppy?.log('[DragDrop] Files were dropped');
    const files = this.adaptFiles(event.dataTransfer.files);
    addFiles(files, 'DragDrop', this.props.uppy, this.props.fileMeta, this.props.referenceId);
  };

  handleDragOver: DragEventHandler = (event) => {
    stopEvent(event);

    const files = _.includes(event.dataTransfer.types, 'Files');
    if (!files) {
      return;
    }

    // 1. Add a small (+) icon on drop
    // (and prevent browsers from interpreting this as files being _moved_ into the browser, https://github.com/transloadit/uppy/issues/1978)
    event.dataTransfer.dropEffect = 'copy';

    clearTimeout(this.removeDragOverClassTimeout);
    this.setState({ isDraggingOver: true });
  };

  handleDragLeave: DragEventHandler = (event) => {
    stopEventPropagation(event);

    clearTimeout(this.removeDragOverClassTimeout);
    // Timeout against flickering, this solution is taken from drag-drop library. Solution with 'pointer-events: none' didn't work across browsers.
    this.removeDragOverClassTimeout = setTimeout(() => {
      this.setState({ isDraggingOver: false });
    }, 50);
  };

  showUploadDialog = () => this.input.current?.click();

  render() {
    const { multiple = true, children, disabled, accept, DragView, className } = this.props;
    const { isDraggingOver } = this.state;

    return (
      <Box
        className={className}
        onDragLeave={disabled ? undefined : this.handleDragLeave}
        onDragOver={disabled ? undefined : this.handleDragOver}
        onDrop={disabled ? undefined : this.handleDrop}
      >
        <FileInput
          accept={accept}
          disabled={disabled}
          fileMeta={this.props.fileMeta}
          maxFileSizeInMB={this.props.maxFileSizeInMB}
          maxNumberOfFiles={this.props.maxNumberOfFiles}
          multiple={multiple}
          ref={this.input}
          referenceId={this.props.referenceId}
          uppy={this.props.uppy}
        />
        {children}
        {isDraggingOver ? DragView : null}
      </Box>
    );
  }
}

export default DragDrop;
