import Stack from '@unifyapps/ui/_components/Stack';
import { Loader } from '@unifyapps/ui/components/Loader';
import { Typography } from '@unifyapps/ui/components/Typography';
import { memo } from 'react';

function LoadingState(props: { title: string; description?: string }) {
  return (
    <Stack className="bg-brand-tertiary rounded-4xl p-sm mx-auto h-40 w-[300px] items-center justify-center self-center">
      <Stack
        className="gap-xl p-xl size-full items-center justify-center rounded-sm bg-[white]"
        direction="column"
      >
        <Loader size="sm" variant="circle" />
        <Stack className="gap-y-xs items-center" direction="column">
          <Typography color="text-secondary" variant="text-md" weight="semi-bold">
            {props.title}
          </Typography>
          {props.description ? (
            <Typography
              className="text-center"
              color="text-tertiary"
              variant="text-sm"
              weight="regular"
            >
              {props.description}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}

const MediaViewerLoadingState = memo(LoadingState);
export default MediaViewerLoadingState;
