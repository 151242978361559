import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useTranslation } from '@unifyapps/i18n/client';
import { Button } from '@unifyapps/ui/components/Button';
import SvgDownloadCloud02 from '@unifyapps/icons/outline/DownloadCloud02';
import { useMemo } from 'react';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import type { MediaTypeViewerProps } from '../types';
import MediaViewerEmptyState from '../components/EmptyState';

const ICON_COMPONENT = <FeaturedIcon Icon={SvgDownloadCloud02} color="brand" size="lg" />;
function UnsupportedFileViewer(props: MediaTypeViewerProps) {
  const { t } = useTranslation('common');
  const { media, onDownload: onDownloadProp } = props;

  const onDownload = useEventCallback(() => {
    onDownloadProp(media);
  });

  const downloadButton = useMemo(
    () => (
      <Button
        className="!rounded-4xl"
        color="brand"
        onClick={onDownload}
        size="md"
        startDecoratorComponent={SvgDownloadCloud02}
        variant="solid"
      >
        {t('common:Actions.Download')}
      </Button>
    ),
    [t, onDownload],
  );

  return (
    <MediaViewerEmptyState
      IconNode={ICON_COMPONENT}
      description={t('common:FilePreviewNoAvailableDescription', {
        context: media.caption ? 'withname' : undefined,
        fileName: media.caption,
      })}
      primaryAction={downloadButton}
      title={t('common:FilePreviewNotAvailable')}
    />
  );
}

export default UnsupportedFileViewer;
