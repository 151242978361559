import { Typography } from '@unifyapps/ui/components/Typography';
import type { UATableCellProps } from './types';

function TextCell(props: UATableCellProps) {
  const { cell, fallback } = props;
  const value = cell.getValue() as string | undefined;

  if (!value) {
    return fallback || null;
  }

  return (
    <Typography as="p" className="flex-1 truncate" color="text-primary" variant="text-sm">
      {value}
    </Typography>
  );
}

export default TextCell;
