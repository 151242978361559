const I_FRAME_NAME = 'downloadFileInSameTab';
const I_FRAME_TITLE = 'Download file in same tab';

export const downloadFileInSameTab = (url: string) => {
  const iframesList = document.getElementsByTagName('iframe');

  if (iframesList.length > 0 && iframesList.namedItem(I_FRAME_NAME)) {
    return window.open(url, I_FRAME_NAME);
  }

  const element = document.getElementsByTagName('body')[0];
  const newIframe = document.createElement('iframe');
  newIframe.name = I_FRAME_NAME;
  newIframe.title = I_FRAME_TITLE;
  newIframe.style.display = 'none';

  element.appendChild(newIframe);

  window.open(url, I_FRAME_NAME);
};
