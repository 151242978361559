import { ImageWithFallback } from '../../ImageWithFallback';
import type { MediaTypeViewerProps } from '../types';

export const IMAGE_FALLBACK_URL =
  "data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.625 6.66667C4.625 3.32995 7.32995 0.625 10.6667 0.625H24C24.1544 0.625 24.3024 0.686329 24.4116 0.795494L35.2045 11.5884C35.3137 11.6976 35.375 11.8456 35.375 12V33.3333C35.375 36.6701 32.6701 39.375 29.3333 39.375H10.6667C7.32994 39.375 4.625 36.6701 4.625 33.3333V6.66667Z' stroke='%23CFD4DE' stroke-width='1.25' /%3E%3Cpath d='M25.2499 30.75H26.0076C26.736 30.75 27.1002 30.75 27.301 30.5981C27.4758 30.4658 27.5841 30.2636 27.5971 30.0447C27.6121 29.7934 27.4101 29.4904 27.0061 28.8844L24.7483 25.4977C24.4145 24.9969 24.2476 24.7465 24.0372 24.6593C23.8532 24.583 23.6465 24.583 23.4626 24.6593C23.2522 24.7465 23.0852 24.9969 22.7514 25.4977L22.1933 26.3349M25.2499 30.75L19.4865 22.4251C19.155 21.9464 18.9893 21.707 18.7823 21.6228C18.6012 21.5492 18.3985 21.5492 18.2174 21.6228C18.0104 21.707 17.8447 21.9464 17.5132 22.4251L13.0535 28.8669C12.6312 29.4769 12.4201 29.7819 12.4321 30.0355C12.4426 30.2564 12.55 30.4614 12.7257 30.5957C12.9274 30.75 13.2983 30.75 14.0401 30.75H25.2499ZM26.7499 19.5C26.7499 20.7426 25.7425 21.75 24.4999 21.75C23.2572 21.75 22.2499 20.7426 22.2499 19.5C22.2499 18.2574 23.2572 17.25 24.4999 17.25C25.7425 17.25 26.7499 18.2574 26.7499 19.5Z' stroke='%235C37EB' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E";

function ImageViewer(props: MediaTypeViewerProps) {
  const { media } = props;

  return (
    <ImageWithFallback
      alt={media.caption || media.name}
      className="size-full object-contain"
      fallbackImgClassname="text-icon-tertiary size-16 m-auto"
      fallbackSrc={IMAGE_FALLBACK_URL}
      src={media.url}
    />
  );
}

export default ImageViewer;
