interface StopPropagationEventType {
  stopPropagation: () => void;
}

export const stopEventPropagation = (event: StopPropagationEventType) => {
  event.stopPropagation();
};

interface PreventEventDefaultType {
  preventDefault: () => void;
}

export const preventEventDefault = (event: PreventEventDefaultType) => {
  event.preventDefault();
};

export const stopEvent = (event: StopPropagationEventType & PreventEventDefaultType) => {
  stopEventPropagation(event);
  preventEventDefault(event);
};
