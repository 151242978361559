import { useTranslation } from '@unifyapps/i18n/client';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import { Link } from '@unifyapps/ui/components/Link';
import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import type { IconComponentType } from '@unifyapps/ui/types';
import React from 'react';
import { clsx } from 'clsx';
import UploadCloud02 from '@unifyapps/icons/outline/UploadCloud02';

export interface UploaderProps {
  disabled?: boolean;
  title?: React.ReactNode;
  className?: string;
  description?: {
    label?: string;
    className?: string;
  };
  Icon?: IconComponentType;
}

function Uploader(props: UploaderProps) {
  const { t } = useTranslation(['objects', 'common']);
  const { description, Icon, title, disabled, className } = props;

  return (
    <Stack
      alignItems="center"
      className={clsx(
        'py-xl px-lg gap-md rounded-xl border border-dashed',
        disabled
          ? 'bg-disabled_subtle border-disabled_subtle cursor-not-allowed'
          : 'border-secondary cursor-pointer',
        className,
      )}
      direction="row"
      justifyContent="space-between"
    >
      <Stack direction="column">
        <Typography color="text-tertiary" variant="text-sm" weight="semi-bold">
          {title ? (
            title
          ) : (
            <Stack className="gap-x-1" direction="row">
              <Link color="brand" disabled={disabled}>
                {t('objects:ClicktoUpload')}
              </Link>
              <Typography variant="text-sm" weight="regular">
                {t('objects:OrDragandDrop')}
              </Typography>
            </Stack>
          )}
        </Typography>
        {description?.label ? (
          <Typography
            className={clsx('mt-xs', description.className)}
            color="text-tertiary"
            variant="text-xs"
            weight="regular"
          >
            {description.label}
          </Typography>
        ) : null}
      </Stack>
      <FeaturedIcon Icon={Icon ?? UploadCloud02} className="bg-primary" color="brand" size="md" />
    </Stack>
  );
}

export default Uploader;
