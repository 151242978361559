export const formatPercent = ({
  value,
  locale,
  maximumFractionDigits,
}: {
  value: number;
  locale?: string;
  maximumFractionDigits?: number;
}) => {
  try {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits,
      style: 'percent',
      // when style is percent, the value is multiplied by 100 by NumberFormat
    }).format(value / 100);
  } catch (e) {
    return String(value);
  }
};
