import _findKey from 'lodash/findKey';
import { UploadError } from './types';

const errorMatcher = {
  [UploadError.FileSize]: 'exceeds maximum allowed size',
  [UploadError.UnSupportedType]: 'You can only upload',
  [UploadError.DuplicateFile]: 'Cannot add the duplicate file',
};

export const getUploadError = (error: Error) => {
  return _findKey(errorMatcher, (eM) => error.message.includes(eM)) as UploadError | undefined;
};
