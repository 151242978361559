import React, { forwardRef, memo, useCallback } from 'react';
import match from 'mime-match';
import { ACCEPT_ALL } from './const';

enum FileTypeErrorEnum {
  FileTypeNotAllowed = 'FILE_TYPE_NOT_ALLOWED',
}

const checkFilesForValidType = (accept?: string, files?: FileList | null) => {
  let allFilesAreValid = false;

  if (files?.length) {
    const filesArray = Array.from(files);
    allFilesAreValid = filesArray.every((file) => checkForValidFileType(accept, file));
  }

  return allFilesAreValid;
};

const checkForValidFileType = (accept?: string, file?: File) => {
  if (accept === ACCEPT_ALL) return true;

  /**
   * if accept prop and file exists
   */
  if (accept && file) {
    const allowedFileTypes = accept.split(',').map((t) => t.trim());

    const isCorrectFileType = allowedFileTypes.some((type) => {
      /**
       * check if this is a mime-type
       */
      if (type.includes('/')) {
        if (!file.type) return false;
        return match(file.type.replace(/;.*?$/, ''), type);
      }

      /**
       * otherwise this is likely an extension
       */
      if (file.name.toLowerCase().endsWith(type.toLowerCase())) {
        return true;
      }
      return false;
    });

    return isCorrectFileType;
  }

  return true;
};

const HtmlFileInput = forwardRef<
  HTMLInputElement,
  Omit<React.HTMLProps<HTMLInputElement>, 'type'> & {
    onFileSelectError?: (error?: typeof FileTypeErrorEnum) => void;
  }
>((props, ref) => {
  const { accept, onChange, onDrop, onFileSelectError, ...restProps } = props;

  const onFileDropHandle = useCallback(
    (e: React.DragEvent<HTMLInputElement>) => {
      const files = e.dataTransfer.files;

      const allFilesValid = checkFilesForValidType(accept, files);

      if (!allFilesValid) {
        e.preventDefault();
        onFileSelectError?.(FileTypeErrorEnum);
      } else {
        onDrop?.(e);
      }
    },
    [accept, onDrop, onFileSelectError],
  );

  const onFileChangeHandle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      const allFilesValid = checkFilesForValidType(accept, files);

      if (!allFilesValid) {
        e.preventDefault();
        onFileSelectError?.(FileTypeErrorEnum);
      } else {
        onChange?.(e);
      }
    },
    [accept, onChange, onFileSelectError],
  );

  return (
    <input
      accept={accept}
      onChange={onFileChangeHandle}
      onDrop={onFileDropHandle}
      ref={ref}
      type="file"
      {...restProps}
    />
  );
});

HtmlFileInput.displayName = 'HtmlFileInput';

export { FileTypeErrorEnum };
export default memo(HtmlFileInput);
