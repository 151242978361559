import type { MediaTypeViewerProps } from '../types';

function VideoViewer(props: MediaTypeViewerProps) {
  const { media } = props;

  // eslint-disable-next-line jsx-a11y/media-has-caption -- todo
  return <video className="size-full" controls src={media.url} />;
}

export default VideoViewer;
